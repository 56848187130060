import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Cocktails NOT FOUND</h1>
    <p>You looked for a cocktail that does not exist.</p>
    <p>
      Do you want to see it in our site? Visit our Facebook page to suggest it!
    </p>
  </Layout>
);

export default NotFoundPage;
